import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home, { loader as homePageLoader } from './pages/Home';
import About, { loader as testimonialsLoader } from './pages/About';
import Donations from './pages/Donations';
import Events, { loader as eventsLoader } from './pages/Events';
import Galleries, { loader as galleriesLoader } from './pages/Galleries';
import EventDetails, { loader as eventDetailsLoader } from './pages/EventDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Sermons, { loader as sermonsLoader } from './pages/Sermons';
import Contact from './pages/Contact';
import Layout from './components/Layout';

import './App.css';

export default function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: '/',
                    element: <Home />,
                    loader: homePageLoader
                },
                {
                    path: 'about',
                    element: <About />,
                    loader: testimonialsLoader

                },
                {
                    path: 'donations',
                    element: <Donations />
                },
                {
                    path: 'privacy-policy',
                    element: <PrivacyPolicy />
                },
                {
                    path: 'galleries',
                    element: <Galleries />,
                    loader: galleriesLoader
                },
                {
                    path: 'contacts',
                    element: <Contact />
                },
                {
                    path: 'events',
                    element: <Events />,
                    loader: eventsLoader
                },
                {
                    path: 'events/:slug',
                    element: <EventDetails />,
                    loader: eventDetailsLoader
                },
                {
                    path: 'sermons',
                    element: <Sermons />,
                    loader: sermonsLoader
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}
