import PrimaryHeaders from '../components/PrimaryHeaders';

export default function Donations() {
    return (
        <section className='my-20 py-8 '>
            <div className='md:max-w-7xl m-auto p-4 bg-slate-50 rounded-lg shadow-sm'>
                <div className='flex flex-center'>
                    <div className='flex flex-col items-center lg:max-w-lg m-auto py-8'>
                        <PrimaryHeaders
                            title='Donations'
                            subTitle='There is joy in giving'
                        />
                        <p className='mt-6 text-lg leading-8 text-gray-700'>
                            Welcome to our online donation page! Your generosity
                            enables us to carry out the mission of our church
                            and make a positive impact in our community and all
                            across the globe. As you give, remember the words of
                            2 Corinthians 9:7: &quot;Each of you should give
                            what you have decided in your heart to give, not
                            reluctantly or under compulsion, for God loves a
                            cheerful giver.&quot; Thank you for partnering with
                            us in spreading God&apos;s love. Your contributions
                            matter, and together, we can make a difference.
                            You can reach out to us
                            via our email or telephone for any possible contributions toward the work of the gospel.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
