import { Fragment } from 'react';
import {
  Dialog,
  Transition,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';

import Cookie from '../Cookie';
import { useCookie } from '../../store/cookie';

export default function CookieModal({ show }: { show: boolean }) {
  const { setCookie } = useCookie();
  return (
    <Transition show={show} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='absolute bottom-0 overflow-hidden rounded-t-lg bg-white transition-all'>
                <Cookie
                  onAccept={() => setCookie('yes')}
                  onReject={() => setCookie('no')}
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}