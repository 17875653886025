import {
    Fa1,
    Fa2,
    Fa3,
    Fa4,
    Fa5,
    Fa6,
    Fa7,
    Fa8,
    Fa9,
    FaCircleDot
} from 'react-icons/fa6';

export default function PrivacyPolicy() {
    return (
        <div className='my-20 md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm p-8 border-t-4 border-indigo-600'>
            <h1 className='text-base font-semibold leading-7 text-indigo-600 uppercase'>
                Privacy Policy
            </h1>
            <div className='my-6 leading-8 text-gray-700'>
                <p className='text-lg mb-4'>
                    Privacy Policy for{' '}
                    <strong>Grace Chapel, RCCG Turku.</strong>
                </p>
                <p className='mb-4'>
                    This Privacy Policy is designed to inform you about the
                    types of information we collect, how we use it, and how we
                    protect your privacy.
                </p>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa1
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <div className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            Information We Collect:
                        </strong>{' '}
                        <p>
                            <span className='font-bold'>a.</span>{' '}
                            <span className='text-gray-500'>
                                Personal Information:
                            </span>{' '}
                            We may collect personal information such as names,
                            addresses, email addresses, and phone numbers when
                            voluntarily submitted by visitors through our online
                            forms or during the donation process.
                        </p>
                        <p>
                            <span className='font-bold'>b.</span>{' '}
                            <span className='text-gray-500'>
                                Donation Information:
                            </span>{' '}
                            When you make a donation, we may collect information
                            such as payment details, transaction history, and
                            any other information necessary to process your
                            donation.
                        </p>{' '}
                        <p>
                            <span className='font-bold'>c.</span>
                            <span className='text-slate-500'>
                                Non-personal Information:
                            </span>{' '}
                            We may collect non-personal information, such as
                            browser type, operating system, and IP address, to
                            enhance the functionality of our Website and improve
                            user experience.
                        </p>
                    </div>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa2
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <div className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            How We Use Your Info:
                        </strong>{' '}
                        <p>
                            <span className='font-bold'>a.</span>{' '}
                            <span className='text-gray-500'>Donations:</span> We
                            use the information provided during the donation
                            process to process transactions, send receipts, and
                            communicate with donors about their contributions.
                        </p>
                        <p>
                            <span className='font-bold'>b.</span>{' '}
                            <span className='text-gray-500'>
                                Communication:
                            </span>{' '}
                            With your consent, we may use your contact
                            information to send newsletters, updates, and other
                            relevant information about our church and its
                            activities.
                        </p>
                        <p>
                            <span className='font-bold'>c.</span>{' '}
                            <span className='text-gray-500'>Analytics:</span>{' '}
                            Non-personal information may be used for statistical
                            analysis, improving our Website, and tailoring
                            content to better meet the needs of our visitors.
                        </p>
                    </div>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa3
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            How We Protect Your Information:
                        </strong>{' '}
                        We implement reasonable security measures to protect
                        your personal information. However, no data transmission
                        over the internet or electronic storage is completely
                        secure, so we cannot guarantee absolute security.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa4
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            Third-Party Services:
                        </strong>{' '}
                        We may use third-party services for payment processing
                        and analytics. These services have their own privacy
                        policies, and we encourage you to review them.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa5
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>Cookies:</strong> Our
                        Website may use cookies to enhance your browsing
                        experience. You can modify your browser settings to
                        control the use of cookies.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa6
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            Your Choices:
                        </strong>{' '}
                        You have the right to opt-out of receiving
                        communications from us and to request access,
                        correction, or deletion of your personal information.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa7
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            Children&apos;s Privacy:{' '}
                        </strong>
                        Our Website is not directed at children under 13, and we
                        do not knowingly collect personal information from
                        children.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa8
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>
                            This Privacy Policy:{' '}
                        </strong>
                        Changes to We may update this Privacy Policy to reflect
                        changes in our practices. The revised policy will be
                        effective immediately upon posting.
                    </p>
                </div>
                <div className='flex gap-x-3'>
                    <div className='flex relative'>
                        <Fa9
                            className='mt-1 h-5 w-5 flex-none text-indigo-600'
                            aria-hidden='true'
                        />
                        <FaCircleDot
                            className='mt-1 h-1 w-1 flex-none text-indigo-600 absolute top-4 left-5'
                            aria-hidden='true'
                        />
                    </div>
                    <p className='text-md mb-4'>
                        <strong className='text-slate-500'>Contact Us:</strong>{' '}
                        If you have any questions or concerns about this Privacy
                        Policy, please contact us at{' '}
                        <strong className='text-slate-500'>
                            info@gracechapel.fi/+358-45-6352887/Vähä-hamenkatu
                            12, 20398, Turku.
                        </strong>{' '}
                        By using our Website, you consent to the terms of this
                        Privacy Policy.
                    </p>
                </div>
            </div>
        </div>
    );
}
