import { useQuery } from '@tanstack/react-query';
import NoData from '../components/NoData';
import PrimaryHeaders from '../components/PrimaryHeaders';
import { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { getResources, queryClient } from '../utils/requests';
import { LoaderFunction } from 'react-router-dom';
import { ENV } from '../utils/contants';
import Loader from '../components/Loader';

export default function GalleriesPage() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const { data, isLoading } = useQuery({
    queryKey: ['galleries'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'galleries/images/all' }),
    });

    const imageUrls = data?.data

    const images = imageUrls.reduce((prev: string[], cur: { images: string[] }) => {
        return prev.concat(cur.images);
    }, []);

    const handleNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex(
            prevIndex => (prevIndex - 1 + images.length) % images.length
        );
    };

    return (
        <>
            {isLoading ? <Loader show={isLoading } /> : <div className='my-20 py-8'>
            {images && images.length > 0 ? (
                <div className='md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm p-8'>
                    <PrimaryHeaders
                        className='text-center'
                        title='Gallery'
                        subTitle='Some of our great moments'
                    />
                    {images && images.length > 0 && (
                        <img
                            src={`${ENV.images}/${images[currentImageIndex]}`}
                            alt='Gallery'
                            className='rounded md:w-full md:h-full mt-16'
                            width={900}
                            height={100}
                        />
                    )}
                    <div className='w-[10rem] bg-gray-200 h-10 flex justify-between items-center m-auto mt-4 p-4 rounded-3xl'>
                        <div
                            className='w-6 h-6 cursor-pointer'
                            onClick={handlePrevImage}
                        >
                            <FaChevronLeft
                                className='h-full w-full text-indigo-600'
                                aria-hidden='true'
                            />
                        </div>
                        <div
                            className='w-6 h-6 right-0 cursor-pointer'
                            onClick={handleNextImage}
                        >
                            <FaChevronRight
                                className='h-full w-full text-indigo-600'
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NoData />
            )}
        </div>}
        </>
    );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['galleries'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'galleries/images/all' }),
  });
}