import { LoaderFunction } from 'react-router-dom';
import PrimaryHeaders from '../components/PrimaryHeaders';
import EventListItem from '../components/EventListItem';
import NoData from '../components/NoData';
import { IEvent } from '../types/event';
import { getResources, queryClient } from '../utils/requests';
import { useQuery } from '@tanstack/react-query';
import Loader from '../components/Loader';

export default function Events() {
    const { data, isLoading } = useQuery({
    queryKey: ['events'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'events/status/upcoming/limited' }),
    });

    const events = data?.data;

    return (
        <>
            {isLoading ? <Loader show={isLoading} /> : <div className='m-auto max-w-7xl my-20 p-8'>
            {events && events.length > 0 ? (
                <>
                    <PrimaryHeaders
                        title='Events'
                        subTitle='Up-coming events'
                    />
                    <ul className='flex justify-center flex-col my-20'>
                        {events.map((event: IEvent) => (
                            <EventListItem
                                key={event.id}
                                id={event.id}
                                slug={event.slug}
                                title={event.title}
                                fee={event.fee}
                                images={event.images}
                                location={event.location}
                                startDate={event.startDate}
                                endDate={event.endDate}
                                isPublished={event.isPublished}
                                description={event.description}
                                organizer={event.organizer}
                                email={event.email}
                                telephone={event.telephone}
                            />
                        ))}
                    </ul>
                </>
            ) : (
                <NoData />
            )}
        </div>}
        </>
        
    );
}

export async function loader(): Promise<LoaderFunction> {
  return await queryClient.fetchQuery({
    queryKey: ['events'],
    queryFn: ({ signal }) =>
      getResources({ signal, url: 'events/status/upcoming/limited' }),
  });
}


