import Navigation from './Navigation';
import Footer from './Footer';
import { Outlet } from 'react-router';
import { useCookie } from '../store/cookie';
import CookieModal from './modals/CookieModal';

export default function Layout() {
    const { acknowledged } = useCookie();
    return (
        <div className='relative'>
            <Navigation />
            <Outlet />
            <Footer />
            <CookieModal show={acknowledged === 'default'} />
        </div>
    );
}
