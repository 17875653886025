import { Link } from 'react-router-dom';

type CookieProps = {
  className?: string;
  onAccept: () => void;
  onReject: () => void;
};

export default function Cookie({ className, onAccept, onReject }: CookieProps) {

  return (
    <div
      className={`${
        className && className
      } p-4 bg-white border-t-2 ring-green-600 mx-auto py-8`}
    >
      <div className='max-w-7xl m-auto'>
        <p className='mb-4 text-sm text-slate-600 max-w-7xl'>
          This website uses cookies to provide necessary site functionality and improve your online experience. By using this website, you agree to the use of cookies as outlined in our {' '}
          <span>
            <Link
              to='/privacy-policy'
              className='text-indigo-400 hover:text-indigo-700 transition-colors'
            >
              Privacy policy
            </Link>
          </span>
        </p>
        <div className='flex gap-x-4 w-max'>
          <button className='inline-block py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white cursor-pointer tracking-wider mt-5 bg-gray-600 hover:bg-gray-700' type='button' onClick={onAccept}>
            Accept
          </button>
          <button className='inline-block py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white cursor-pointer tracking-wider mt-5 bg-red-600 hover:bg-red-700' type='button' onClick={onReject}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}