import { IPodcast } from '../types/podcast';
import { ENV } from '../utils/contants';

export default function AudioMessage({
    title,
    genre,
    audios,
    author
}: IPodcast) {
    return (
        <article className='mx-auto bg-white rounded-full shadow-md overflow-hidden md:max-w-2xl my-4 text-center'>
            <div className='bg-white p-4'>
                <h1 className='my-4 text-sm'>{`${title.substring(0, 15)}...`}</h1>
                <span className='text-gray-500'>{genre}</span>
                {audios && audios.length > 0 && (
                    <audio controls className='my-8 mx-auto'>
                        <source
                            src={`${ENV.audios}/${audios[0].url}`}
                            type='audio/mpeg'
                        />
                        Your browser does not support the audio element.
                    </audio>
                )}
                {author && (
                    <p className='text-gray-500'>By: {author}</p>
                )}
            </div>
        </article>
    );
}
