import { useState } from 'react';
import PrimaryHeaders from '../components/PrimaryHeaders';
import { IPodcast } from '../types/podcast';
import AudioMessage from '../components/AudioMessage';
import Pagination from '../components/Pagination';
import { transformToCamelCase } from '@church/utilities';
import { getPaginatedData, queryClient } from '../utils/requests';
import NoData from '../components/NoData';
import { useQuery } from '@tanstack/react-query';
import { LoaderFunction } from 'react-router-dom';
import Loader from '../components/Loader';

export default function Sermons() {
    const [currentPage, setCurrentPage] = useState<number>(1);

   const { data, isLoading } = useQuery({
    queryKey: ['sermons', currentPage],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage,
        perPage: 20,
        resource: 'podcasts/published/all/paginate',
      }),
  });

    const paginatedData = transformToCamelCase(data?.data);

    const handlePageChange = (
        direction: 'prev' | 'next' | 'specific',
        pageNumber?: number
    ) => {
        if (direction === 'specific' && pageNumber) {
            setCurrentPage(pageNumber);
        } else if (direction === 'prev') {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next') {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            {isLoading ? <Loader show={isLoading} /> : <section className='my-20 py-8'>
            {paginatedData && paginatedData.data.length > 0 ? (
                <>
                    <div className='md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm'>
                        <div className='text-center pt-8'>
                            <PrimaryHeaders
                                title='Sermons'
                                subTitle='All messages from the altar'
                            />
                        </div>
                        {paginatedData &&
                            paginatedData.data &&
                            paginatedData.data.length > 0 && (
                                <div className='flex justify-center items-center gap-2 py-8 flex-wrap'>
                                    {paginatedData.data.map(
                                        (message: IPodcast) => (
                                            <AudioMessage
                                                id={message.id}
                                                key={message.id}
                                                title={message.title}
                                                audios={message.audios}
                                                summary={message.summary}
                                                status={message.status}
                                                genre={message.genre}
                                                author={message.author}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                    </div>
                    {paginatedData &&
                        paginatedData.total > paginatedData.perPage && (
                            <Pagination
                                onHandlePageChange={handlePageChange}
                                total={paginatedData.total}
                                perPage={paginatedData.perPage}
                                currentPage={paginatedData.currentPage}
                            />
                        )}
                </>
            ) : (
                <NoData />
            )}
        </section>}
        </>
    );
}

export async function loader(): Promise<LoaderFunction> {
  const page = 1;
  return await queryClient.fetchQuery({
    queryKey: ['sermons'],
    queryFn: ({ signal }) =>
      getPaginatedData({
        signal,
        currentPage: page,
        perPage: 20,
        resource: 'podcasts/published/all/paginate',
      }),
  });
}
