import { Link } from 'react-router-dom';
import { ENV } from '../utils/contants';

export default function GalleryImage({ src }: { src: string }) {
    return (
        <Link to='/galleries'>
            <div className='rounded-md md:w-[20rem]'>
                <img
                    className='w-full h-full object-cover rounded-md'
                    src={`${ENV.images}/${src}`}
                    alt='Gallery'
                />
            </div>
        </Link>
    );
}
