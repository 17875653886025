import { QueryClient } from '@tanstack/react-query';
import { ENV, PODCAST_GENRES } from './contants';

export const queryClient = new QueryClient();

interface Options {
  headers?: Record<string, string>;
  body?: any;
  signal?: AbortSignal;
}

export class Http {
  private static defaultHeaders = {
    'X-Client-Token': `${ENV.clientToken}`,
    Accept: 'application/json',
  };

  private static async request(
    url: string,
    method: string,
    options: Options
  ): Promise<any> {
    const headers = {
      ...this.defaultHeaders,
      ...options.headers,
    };

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: options.body,
        signal: options.signal,
      });
      const data = await response.json();
      if (!response.ok) {
        return data;
      }
      return data;
    } catch (error: any) {
      return { error: { message: error.statusText }, statusCode: 500 };
    }
  }

  static async post(url: string, options: Options) {
    return await this.request(url, 'POST', options);
  }

  static async get(url: string, options: Options) {
    return await this.request(url, 'GET', options);
  }

  static async delete(url: string, options: Options) {
    return await this.request(url, 'DELETE', options);
  }

  static async patch(url: string, options: Options) {
    return await this.request(url, 'PATCH', options);
  }

  static async put(url: string, options: Options) {
    return await this.request(url, 'PUT', options);
  }
}

export async function getResources({
  signal,
  url,
}: {
  signal: AbortSignal;
  url: string;
}): Promise<any> {
  const fullUrl = `${ENV.baseUrl}/${url}`;
  return await Http.get(`${fullUrl}`, {
    signal,
  });
}

export async function getById({
  signal,
  url,
}: {
  signal: AbortSignal;
  url: string;
}): Promise<any> {
  const fullUrl = `${ENV.baseUrl}/${url}`;
  return await Http.get(fullUrl, {
    signal,
  });
}

export async function getPaginatedData({
  signal,
  currentPage,
  perPage,
  resource,
}: {
  signal: AbortSignal;
  currentPage: number;
  perPage: number;
  resource: string;
}) {
  const url = `${ENV.baseUrl}/${resource}`;
  return await Http.get(`${url}?page=${currentPage}&&per_page=${perPage}&&genre[]=${[
            PODCAST_GENRES.sermon
        ]}`, {
    signal,
  });
}