import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

type Acknowledged = 'yes' | 'no' | 'default';

interface CookieContextType {
  acknowledged: Acknowledged;
  setCookie: (acknowledged: Acknowledged) => void;
}

const CookieContext = createContext<CookieContextType>({
  acknowledged: 'default',
  setCookie: () => {},
});

export const useCookie = () => {
  const context = useContext(CookieContext);
  if (!context) {
    throw new Error('useCookie must be used within a CookieProvider');
  }
  return context;
};

export const CookieProvider = ({ children }: { children: ReactNode }) => {
  const [acknowledged, setAcknowledged] = useState<Acknowledged>(() => {
    const acknowledged = sessionStorage.getItem('acknowledged');
    return acknowledged === 'yes' || acknowledged === 'no' || acknowledged === 'default'
      ? (acknowledged as Acknowledged)
      : 'default'; 
  });

  useEffect(() => {
    sessionStorage.setItem('acknowledged', acknowledged);
  }, [acknowledged]);

  const setCookie = (value: Acknowledged) => {
    setAcknowledged(value);
    
  };

  return (
    <CookieContext.Provider value={{ acknowledged, setCookie }}>
      {children}
    </CookieContext.Provider>
  );
};