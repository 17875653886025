import { Fragment } from 'react';
import {
  Dialog,
  Transition,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';

import logo from '../assets/rccg-logo.png';

export default function Loader({ show }: { show: boolean }) {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-white transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='absolute left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden bg-transparent transition-all'>
                <div className='w-24 h-24 animate-bounce'>
                  <img
                    width={96}
                    height={96}
                    src={logo}
                    alt='Loading spinner'
                />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}